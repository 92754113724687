<template>
  <div class="picture-detail-container container_media">
    <!-- <div class="back_lastpage" @click="back">
      <img src="@/assets/image/back.png" alt="">
      <span>返回上一级</span>
    </div> -->
    <div class="crumb-wapper">
      <vf-breadcrumb :data="breadData"></vf-breadcrumb>
    </div>
    <div class="content">
      <div class="lf-wrap">
        <div class="picture">
          <img v-if="pictureInfo" :style="pictureInfo.width > pictureInfo.height ? 'width:100%;' : 'height:100%;'"
            :src="pictureInfo.picture_url" alt="">
        </div>
        <p class="statement">
          声明：本站仅对作品中独创性部分享有著作权或已取得版权方授权。对作品中含有的国旗、国徽、国歌等政治元素不享有权利，仅作为作品整体效果的示例展示，禁止商用。另外您必须遵循相关法律法规规定的使用范围和使用方式，禁止以任何形式歪曲、篡改。
        </p>
        <ul class="keywords" v-if="pictureInfo">
          <li class="label">关键词：</li>
          <li class="key" v-for="(m, i) in pictureInfo.keywords" :key="i">{{ m }}</li>
        </ul>
      </div>
      <div class="rg-wrap">
        <template v-if="pictureInfo">
          <p class="title">{{ pictureInfo.name }}</p>
          <p class="desc">{{ pictureInfo.desc }}</p>
          <ul class="arguments">
            <li class="info">
              <p class="label">
                <img src="@/assets/svg/pictureFilter/scbh.svg" alt="">
                <span class="name">素材编号：</span>
              </p>
              <span class="value">{{ pictureInfo.picture_id }}</span>
            </li>
            <li class="info">
              <p class="label">
                <img src="@/assets/svg/pictureFilter/ssfl.svg" alt="">
                <span class="name">所属分类：</span>
              </p>
              <span class="value" v-if="pictureInfo.cate_info && pictureInfo.cate_info.length > 0">{{
                pictureInfo.cate_info.reduce((prev, curr) => prev + curr.name + '、', '').slice(0, -1) }}</span>
              <span class="value" v-else>-</span>
            </li>
            <li class="info">
              <p class="label">
                <img src="@/assets/svg/pictureFilter/ccxs.svg" alt="">
                <span class="name">尺寸像素：</span>
              </p>
              <span class="value">{{ `${pictureInfo.width}*${pictureInfo.height}px` }}</span>
            </li>
            <div class="rowShow">
              <li class="info">
                <p class="label">
                  <img src="@/assets/svg/pictureFilter/wjdx.svg" alt="">
                  <span class="name">文件大小：</span>
                </p>
                <span class="value">{{ bytesToKB(pictureInfo.size) }}</span>
              </li>
              <li class="info">
                <p class="label">
                  <img src="@/assets/svg/pictureFilter/wjgs.svg" alt="">
                  <span class="name">文件格式：</span>
                </p>
                <span class="value">{{ pictureInfo.extension }}</span>
              </li>
            </div>
          </ul>
          <ul class="impower">
            <!-- <li class="impower-item">授权方式： <span>VRF授权</span></li>
            <li class="impower-item">授权范围： <span>全球无限期商业用途，可以营利为目的，不限宣传载体</span></li> -->
          </ul>
        </template>
        <ul class="operation" v-if="pictureInfo">
          <el-button class="download-btn" type="primary"
            @click="handleDownload({ ...pictureInfo, type: 5, object_id: pictureInfo.picture_id })">立即下载</el-button>
          <li class="operation-item" @click="handleShare('', pictureInfo.picture_id, 5, pictureInfo.name)">
            <img src="@/assets/svg/operation/share.svg" alt="">
          </li>
          <li class="operation-item" @click="handleSoundCollect(pictureInfo)">
            <img :src="require(`@/assets/svg/operation/${pictureInfo.is_collect === 1 ? 'collected' : 'collect'}.svg`)"
              alt="">
          </li>
        </ul>
      </div>
    </div>
    <section class="recommend" v-if="pictureInfo">
      <p class="title">相似作品推荐</p>
      <div class="likeList">
        <pictureList class="pictureList" :list="pictureInfo.like" :endPage="true" />
      </div>
    </section>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import { mapGetters } from 'vuex'
import pictureList from './components/pictureList.vue'
import { fetchPictureInfo } from '@/api/picture'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'VideoDetail',
  components: { pictureList, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '图片', back: true},
        {breadcrumbName: 'name'}

      ],
      picture_id: '',
      pictureInfo: null,
      backUrl: ''
    }
  },
  mixins: [Buttons],
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    '$route.query.time': {
      handler(v) {
        this.pictureInfo = null
        this.picture_id = this.$route.query.picture_id
        this.getDetail()
        console.log(v)
      }
    }
  },
  mounted() {
    const routeQuery = this.$route.query
    this.picture_id = routeQuery.picture_id
    // this.backUrl = `${routeQuery.backUrl}&currentTag=${routeQuery.currentTag || ''}&order=${routeQuery.order || ''}&pattern=${routeQuery.pattern || ''}`
    this.getDetail()
  },
  methods: {
    back() {
      this.$router.go(-1)
      // window.location = this.backUrl
    },
    async handleSoundCollect(item) {
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.picture_id, 5, {
        ...item,
        type: 5,
        labels: item.name
      }, false)
    },
    async getDetail() {
      try {
        const res = await fetchPictureInfo({
          picture_id: this.picture_id
        })
        if (res.code === 0) {
          this.pictureInfo = res.data
          this.breadData[2].breadcrumbName = res.data.name
        }
      } catch (error) {
        console.log(error)
      }
    },
    bytesToKB(bytes) {
      if (bytes === 0) return '0 MB';
      const mb = bytes / 1024;
      return `${mb.toFixed(2)} KB`;
    }
  }
}
</script>

<style lang="scss" scoped>
.picture-detail-container {
  min-height: 100vh;
  padding: 90px 0;
  .crumb-wapper{
    margin-bottom: 20px;
  }
  .back_lastpage {
    margin-bottom: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 14px;
      margin-right: 9px;
    }

    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;

    .lf-wrap {
      width: 60%;

      .picture {
        @include size(100%, 530px);
        @include center-middle-flex;
        background: #FAFAFA;

        img {
          max-width: 100%;
          height: auto;
          max-height: 530px;
        }
      }

      .statement {
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin: 10px 0 24px;
      }

      .keywords {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;

        .label {
          color: #333333;
          margin-right: 16px;
        }

        .key {
          color: #666660;
          background: #FAFAFA;
          border-radius: 4px;
          padding: 2px 8px;
          margin-right: 12px;
        }
      }
    }

    .rg-wrap {
      width: 40%;
      padding: 6px 0 0 40px;
      box-sizing: border-box;

      .title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }

      .desc {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin: 12px 0 20px;
      }

      .arguments {
        width: 100%;
        background: #FAFAFA;
        border-radius: 8px;
        padding: 13px 24px;
        box-sizing: border-box;

        .info {
          margin-bottom: 16px;
          display: flex;
          align-items: center;

          .label {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-right: 16px;
            display: flex;
            align-items: center;

            img {
              @include square(12px);
              margin-right: 4px;
            }
          }

          .value {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }

        .rowShow {
          display: flex;
          align-items: center;

          .info {
            margin-bottom: 0;

            &:last-child {
              margin-left: 50px;
            }
          }
        }
      }

      .impower {
        margin: 20px 0 50px;
        padding-left: 16px;
        box-sizing: border-box;

        .impower-item {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;

          &:last-child {
            margin-top: 12px;
          }
        }
      }

      .operation {
        display: flex;
        align-items: center;

        .download-btn {
          flex: 1;
          height: 54px;
          background: linear-gradient(90deg, #FE7273 0%, #FC4243 100%);
          border-radius: 8px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .operation-item {
          @include square(54px);
          @include center-middle-flex;
          background: #F5F6FA;
          border-radius: 8px;
          margin-left: 12px;
          cursor: pointer;

          img {
            @include square(22px);
          }
        }
      }
    }
  }

  .recommend {
    margin: 60px 0 100px;

    .title {
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
      margin-bottom: 36px;
    }

    .likeList {
      max-height: 784px;
      overflow: hidden;
    }

    .pictureList {
      margin: 0 !important;
    }
  }
}
</style>
